<template>
  <div class="calendar-header no-print">
    <BaseButton
      depressed
      color="blue darken-1"
      small
      title="Move Back 1 Week"
      @click="previousWeek"
      ><VIcon small icon>arrow_left</VIcon><VIcon small icon>arrow_left</VIcon>
    </BaseButton>
    <BaseButton
      depressed
      md-icon="arrow_left"
      md-icon-position="left"
      color="blue darken-1"
      title="Move Back 1 Day"
      small
      @click="previousDay"
      >Day</BaseButton
    >
    <BaseButton
      depressed
      small
      color="blue darken-1"
      @click="updateQueryStartDate(Date.now())"
      >Today</BaseButton
    >
    <BaseButton
      depressed
      md-icon="arrow_right"
      small
      color="blue darken-1"
      title="Move Ahead 1 Day"
      @click="nextDay"
      >Day</BaseButton
    >
    <BaseButton
      depressed
      color="blue darken-1"
      small
      title="Move Ahead 1 Week"
      @click="nextWeek"
      ><VIcon small icon>arrow_right</VIcon
      ><VIcon small icon>arrow_right</VIcon>
    </BaseButton>
    <template v-if="getIsPrintMode">
      <VAutocomplete
        v-model="selectedClientNames"
        class="ma-0 pa-0 mr-2"
        dense
        dark
        hide-details
        :items="getClientNamesFromReports"
        deletable-chips
        small-chips
        multiple
        clearable
        placeholder="Pick Client Name(s)"
        >Client Name</VAutocomplete
      >
      <VAutocomplete
        v-model="selectedJobNumber"
        class="ma-0 pa-0"
        dense
        dark
        hide-details
        :items="getJobNumbersFromReports"
        clearable
        placeholder="Pick Job Number"
        >Job Number</VAutocomplete
      >
      <BaseButton
        small
        :color="isForemanColumnHidden ? 'blue' : 'green accent-4'"
        depressed
        :md-icon-style="isForemanColumnHidden ? '' : 'outlined'"
        :title="isForemanColumnHidden ? 'Show' : 'Hide'"
        :md-icon="foremanToggleIcon"
        md-icon-position="left"
        md-icon-class="mr-2"
        @click="isForemanColumnHidden = !isForemanColumnHidden"
        >Foremen</BaseButton
      >
      <BaseButton
        small
        :color="isShowingCivilJobsOnly ? 'green accent-4' : 'blue'"
        depressed
        :md-icon-style="isShowingCivilJobsOnly ? 'outlined' : ''"
        :title="isShowingCivilJobsOnly ? 'Civil Only' : 'Show All'"
        :md-icon="civilToggleIcon"
        md-icon-position="left"
        md-icon-class="mr-2"
        @click="handleShowOnlyJobType('civil')"
        >Civil Only</BaseButton
      >

      <BaseButton
        small
        :color="isShowingFiberJobsOnly ? 'green accent-4' : 'blue'"
        depressed
        :md-icon-style="isShowingFiberJobsOnly ? 'outlined' : ''"
        :title="isShowingFiberJobsOnly ? 'Fiber Only' : 'Show All'"
        :md-icon="fiberToggleIcon"
        md-icon-position="left"
        md-icon-class="mr-2"
        @click="handleShowOnlyJobType('fiber')"
        >Fiber Only</BaseButton
      >
    </template>
    <VSpacer />
    <BaseButton
      v-if="!getIsPrintMode"
      depressed
      color="blue darken-1"
      small
      md-icon="print"
      :to="{ path: '/print-scheduler', query: $route.query }"
      >Print</BaseButton
    >
    <BaseButton
      v-else
      depressed
      color="blue darken-1"
      small
      md-icon="arrow_left"
      md-icon-position="left"
      :to="{ path: 'scheduler', query: $route.query }"
      >Exit Print Mode</BaseButton
    >
    <NavDropdownMenu v-if="isAuthenticated" small btn-color="red lighten-2" />
  </div>
</template>

<script>
import dayjs from '@utils/dayjsCustomParse.js'
import { mapGetters } from 'vuex'
import NavDropdownMenu from '@components/Nav/NavDropdownMenu'
import VAutocomplete from '@vuetify/VAutocomplete'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  name: 'CalendarHeader',
  components: { NavDropdownMenu, VAutocomplete },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'getIsPrintMode',
      'getJobNumbersFromReports',
      'getClientNamesFromReports',
    ]),
    ...mapFields([
      'isForemanColumnHidden',
      'isShowingCivilJobsOnly',
      'isShowingFiberJobsOnly',
      'selectedClientNames',
      'selectedJobNumber',
    ]),
    startDate() {
      return this.$route.query.startDate
        ? dayjs(this.$route.query.startDate, 'MM-DD-YYYY')
        : dayjs(Date.now())
    }, // startDate
    foremanToggleIcon() {
      return this.isForemanColumnHidden ? 'visibility' : 'visibility_off'
    }, // foremanToggleIcon
    civilToggleIcon() {
      return this.isShowingCivilJobsOnly ? 'filter_alt_off' : 'filter_alt'
    }, // civilToggleIcon
    fiberToggleIcon() {
      return this.isShowingFiberJobsOnly ? 'filter_alt_off' : 'filter_alt'
    }, // fiberToggleIcon
  }, // computed
  beforeDestroy() {
    this.isForemanColumnHidden = false
    this.selectedClientNames = []
    this.selectedJobNumber = null
  },
  methods: {
    // TODO (ES) : Move this to vuex
    nextWeek() {
      this.$router.push({
        query: {
          ...this.$route.query,
          startDate: this.startDate.add(1, 'week').format('MM-DD-YYYY'),
        },
      })
    }, // nextDay

    // TODO (ES) : Move this to vuex
    previousWeek() {
      this.$router.push({
        query: {
          ...this.$route.query,
          startDate: this.startDate.subtract(1, 'week').format('MM-DD-YYYY'),
        },
      })
    }, // previousDay
    // TODO (ES) : Move this to vuex
    nextDay() {
      this.$router.push({
        query: {
          ...this.$route.query,
          startDate: this.startDate.add(1, 'day').format('MM-DD-YYYY'),
        },
      })
    }, // nextDay

    // TODO (ES) : Move this to vuex
    previousDay() {
      this.$router.push({
        query: {
          ...this.$route.query,
          startDate: this.startDate.subtract(1, 'day').format('MM-DD-YYYY'),
        },
      })
    }, // previousDay

    // TODO (ES) : Move this to vuex
    updateQueryStartDate(date) {
      this.$router.push({
        query: {
          ...this.$route.query,
          startDate: dayjs(date).format('MM-DD-YYYY'),
        },
      })
    }, // updateQueryStartDate
    handleShowOnlyJobType(jobType) {
      if (jobType === 'civil') {
        this.isShowingFiberJobsOnly = false
        this.isShowingCivilJobsOnly = !this.isShowingCivilJobsOnly
      } else {
        this.isShowingCivilJobsOnly = false
        this.isShowingFiberJobsOnly = !this.isShowingFiberJobsOnly
      }
    }, // handleShowOnlyJobType
  },
}
</script>

<style lang="scss" scoped>
.calendar-header {
  display: flex;
  align-items: center;
  margin: 10px 0;
  .cal-title {
    align-self: center;
    margin: 10px 20px 0 0;
  }
  button {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
